<template>
	<div class="outer-wrapper">
		<div class="flights results">

			<div class="search-data" v-if="flightsSearch.searchValues">
				<div class="locations">
					<span>
						<span class="start" @click="changeLocation()" :style="{ 'background-image': `url(${outFlightImage})` }" v-html="startLocation">{{ startLocation }}</span>
						<img class="center-icon" @click="swap()" src="@/assets/flights-search/swap-icon.png">
						<span class="end" @click="changeLocation()" :style="{ 'background-image': `url(${inFlightImage})` }" v-html="endLocation">{{ endLocation }}</span>
					</span>
				</div>
				<div class="dates" @click="changeDates()">
					<span class="start">{{formatDate(formattedDates.start)}}</span>
					<img class="center-icon" src="@/assets/flights-search/calendar.png">
					<span class="end">{{formattedDates.end ? formatDate(formattedDates.end) : "--"}}</span>
				</div>
				<div class="other" @click="viewSummary()">
					<img class="details-icon" width="24px" src="@/assets/flights-search/details.png">
					<span class="pax" v-if="totalPax == 1">{{totalPax}} traveller,</span>
					<span class="pax" v-else>{{totalPax}} travellers,</span>
					<span class="class"> {{flightsSearch.searchValues.cabinClass.label}}</span>
					<div style="clear: both"></div>
				</div>
			</div>

			<Loader :loading="flightsSearch.isLoading"/>

			<div v-if="!flightsSearch.isLoading">

				<div class="sort-wrapper">
					<div class="sort" v-if="bestResults && bestResults.cheapest.price !== null">
						<div class="section" :class="{selected: flightsSearch.selectedSort == 'best'}" @click="sortOptions('best')" id="best">
							<!-- TODO: Create helper function to convert "GBP" (etc) into symbols -->
							<div class="type">Best<img src="@/assets/flights-search/best-icon.png"></div>
							<span class="price">£{{ Math.ceil(bestResults.best.price) }}</span>
						</div>
						<div class="section" :class="{selected: flightsSearch.selectedSort == 'cheapest'}" @click="sortOptions('cheapest')" id="cheapest"> 
							<div class="type">Cheapest</div>
							<span class="price">£{{ Math.ceil(bestResults.cheapest.price) }}</span>
						</div>
						<div class="section" :class="{selected: flightsSearch.selectedSort == 'fastest'}" @click="sortOptions('fastest')" id="fastest">
							<div class="type">Fastest</div>
							<span class="price">£{{ Math.ceil(bestResults.fastest.price) }}</span>
							<span class="travel-time">{{ formatHours(bestResults.fastest.data.travel_time) }}</span>
						</div>
						
					</div>
				</div>

				<div class="inner-wrapper" v-if="flightsSearch.searchResults.length > 0">
					<div class="filter-results">
						<img class="plane-icon" width="24px" src="@/assets/flights-search/plane.png" />
						<span class="total-results">
							<span v-if="flightsSearch.filteredSearchResults.length < flightsSearch.searchResults.length">{{ flightsSearch.filteredSearchResults.length }} filtered results from</span><span> {{ flightsSearch.searchResults.length }} total results</span>
						</span>
						<router-link :to="{ name: 'Flights Filters' }" id="filter">
							<img class="filter-icon" width="24px" src="@/assets/flights-search/filter-toggles.png" alt="Filter icon">
						</router-link>
					</div>
				</div>

				<div class="errors" v-if="flightsSearch.searchResultsErrors !== null || error || flightsSearch.filteredSearchResults.length == 0">
					<div class="main-text">Flights not found</div>
					<div class="caption">Please try again</div>
					<ResultIcon class="icon" type="neutral" :message="flightsSearch.searchResultsErrors || 'Try change search parameters or filters'" />
					<button class="button" @click="viewSummary()">Update Search</button>
				</div>		

				<div class="options" v-if="flightsSearch.filteredSearchResults !== []">
					<FlightsOption
						v-for="[key, option] in flightsSearch.filteredSearchResults"
						v-bind:key="key"
						v-bind:data="option"
						:qty="flightsSearch.searchValues.qty"
					/>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import router from "@/router";
import locationDropdown from "@/components/locationDropdown.vue";
import FlightsOption from "@/components/flights/FlightsOption.vue";
import helpers from '@/helpers/helpers.js';
import Loader from '@/components/Loader'
import ResultIcon from '@/components/ResultIcon.vue';

export default {
	name: "flightsSearchResults",
	data() {
		return {
			error: false,
			outFlightImage: require("@/assets/flights-search/flight-out-icon.png"),
			inFlightImage: require("@/assets/flights-search/flight-in-icon.png"),
		};
	},
	components: {
		locationDropdown,
		FlightsOption,
		Loader,
		ResultIcon
	},
	computed: {
		...mapState({
			flightsSearch: state => state.flightsSearch,
		}),
		...mapGetters("flightsSearch", [
			"formattedDates",
			"isReturn",
			"totalPax",
			"bestResults",
			"searchFormErrors",
			"filteredSearchResults",
		]),
		startLocation() {
			if(this.flightsSearch.searchValues.startLocation.code) {
				return this.flightsSearch.searchValues.startLocation.code
			}
			return '<span style="color: grey;">Enter start...</span>';
		},
		endLocation() {
			if(this.flightsSearch.searchValues.endLocation.code) {
				return this.flightsSearch.searchValues.endLocation.code
			}
			return '<span style="color: grey;">Enter end...</span>';
		}
	},
	methods: {
		init() {
			this.mapQueryParams();

			this.error = false;
			if (this.searchFormErrors && !this.searchFormErrors.size > 0) {
				this.submitFlightSearch(); // Only conduct new search if coming from a page that may have changed search values (not including filter pages)
			} else {
				this.error = true;
			}

			this.sortOptions(this.flightsSearch.selectedSort);
		},
		...mapActions({
			submitFlightSearch: "flightsSearch/submitFlightSearch",
			swapLocations: "flightsSearch/swapLocations",
			sortOptions: "flightsSearch/sortOptions",
			mapQueryParams: "flightsSearch/mapQueryParams"
		}),
		changeLocation() {
			router.push({
				name: 'Flights Locations',
				query: {
					trip_id: this.$route.query.trip_id,
					trip_item_id: this.$route.query.trip_item_id,
				}
			})
			e.stopPropagation();
		},
		changeDates() {
			router.push({
				name: 'Flights Dates',
				query: {
					trip_id: this.$route.query.trip_id,
					trip_item_id: this.$route.query.trip_item_id,
				}
			})
		},
		viewSummary() {
			router.push({
				name: 'Flights Search',
				query: {
					trip_id: this.$route.query.trip_id,
					trip_item_id: this.$route.query.trip_item_id,
				}
			})
		},
		formatDate: (date) => helpers.formatDateWithoutDay(date),
		formatHours(minutes) {
			var hours = Math.floor(minutes / 60);
			var minutes = minutes % 60;

			return hours + "h " + minutes + "m";
		},
		swap() {
			this.swapLocations();
			this.init();
		}
	},
	created() {
		this.init();
	}
};
</script>

<style scoped>

.disabled {
	pointer-events: none;
}

/* Small verson of dropdown (search results) */


</style>